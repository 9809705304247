import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { InsightConfig, Metric, MetricType } from "../models";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class MetricService extends ResourceService<Metric> {

    private LOCAL_STORAGE_TRY_ALGORITHM_METRIC = 'servitly-console-try-algorithm-metric-';

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'metrics';
    }

    getPathFromCategory(metricCategory: MetricCategory): string {
        switch (metricCategory) {
            case MetricCategory.COMPUTED:
                return 'computed-data';
            case MetricCategory.INSIGHT:
                return 'insights';
            case MetricCategory.RAW:
                return 'raw-data';
            case MetricCategory.WEAR:
                return 'wear';
            default:
                return '';
        }
    }

    getAlgorithm(id: string): Promise<string> {
        let params = this.getContextParams();
        return firstValueFrom(
            this.httpClient.get(AppConstants.API_BASE_URL + this.getAlgoirithmEnpoint(id), { params: params, observe: "response", responseType: "blob" })
        ).then(response => response.body.text());
    }

    saveAlgorithm(id: string, content: string): Promise<void> {
        const formData = new FormData();
        formData.append('file', new File([content], 'content'));
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getAlgoirithmEnpoint(id), formData, { params: params }));
    }

    import(file: File, category: string, delimiter: string): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = new HttpParams().set('category', category).set('delimiter', delimiter);
        params = this.getContextParams(params);
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + `${this.getEndpoint()}/import`, formData, { params: params }));
    }

    export(category: string): Promise<void> {
        let params = new HttpParams().set('category', category);
        params = this.getContextParams(params);
        return firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + `${this.getEndpoint()}/export`, { params: params, observe: "response", responseType: "blob" }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, 'metrics_export.csv') });
    }

    private getAlgoirithmEnpoint(id: string): string {
        return this.getEndpointById(id) + "/algorithm";
    }

    testAlgorithm(id: string, thingId: string, execute: boolean): Promise<AlgorithmTestOutput> {
        let params = this.getContextParams(new HttpParams().set('thingId', thingId).set('execute', execute));
        return firstValueFrom(this.httpClient.post<AlgorithmTestOutput>(AppConstants.API_BASE_URL + `${this.getEndpointById(id)}/test`, {}, { params: params }));
    }

    saveFormValueInStorage(formValue: any): void {
        let storedFormObj = this.getFormValueInStorage();
        storedFormObj = formValue;
        localStorage.setItem(this.LOCAL_STORAGE_TRY_ALGORITHM_METRIC + this.contextService.getEnvironment().id, JSON.stringify(storedFormObj));
    }

    getFormValueInStorage(): any {
        let storedFormValues = localStorage.getItem(this.LOCAL_STORAGE_TRY_ALGORITHM_METRIC + this.contextService.getEnvironment().id);
        return storedFormValues ? JSON.parse(storedFormValues) : {};
    }

    static isSystemMetric(metric: Metric): boolean {
        return (metric?.mapping?.path || '').startsWith('$internal');
    }

    getMetricFunction(id: string): Promise<string> {
        let params = this.getContextParams();
        return firstValueFrom(
            this.httpClient.get(AppConstants.API_BASE_URL + this.getMetricFunctionEnpoint(id), { params: params, observe: "response", responseType: "blob" })
        ).then(response => response.body.text());
    }

    private getMetricFunctionEnpoint(id: string): string {
        return this.getEndpointById(id) + "/function";
    }

    saveMetricFunction(id: string, content: string): Promise<void> {
        const formData = new FormData();
        formData.append('file', new File([content], 'metricFunction'));
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getMetricFunctionEnpoint(id), formData, { params: params }));
    }

    getInsightConfigurations(): Promise<{ insights: InsightConfig[] }> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get<{ insights: InsightConfig[] }>(this.getInsightConfigEndpoint(), { params: params }));
    }

    getInsightConfigurationById(id: string): Promise<InsightConfig> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get<InsightConfig>(this.getInsightConfigEndpoint() + '/' + id, { params: params }));
    }

    private getInsightConfigEndpoint(): string {
        return AppConstants.API_BASE_URL + 'insightConfigurations';
    }

    static getNavigationPath(metric: Metric): string {
        switch (metric.type) {
            case MetricType.ALGORITHM:
                return 'insights';
            case MetricType.SIMPLE:
            case MetricType.RECORD:
            case MetricType.BLOB:
            case MetricType.VOLATILE:
                return 'raw-data';
            case MetricType.DERIVED:
            case MetricType.COUNTER:
                return 'computed-data';
            case MetricType.WEAR:
                return 'wear';
            default:
                return '';
        }
    }

    getSummary(): Promise<Metric[]> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get<Metric[]>(AppConstants.API_BASE_URL + this.getEndpoint() + '/summary', { params: params }));
    }
}

export enum MetricCategory {
    RAW = 'RAW',
    INSIGHT = 'INSIGHT',
    COMPUTED = 'COMPUTED',
    WEAR = 'WEAR'
}

export interface AlgorithmTestOutput {
    functionInput: object;
    functionOutput: { value: any };
    log: string;
}
