import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { AlertCategoryGroup, AlertDefinition, LocationAlertDefinition } from "../models";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class AlertDefinitionService extends ResourceService<AlertDefinition> {


    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'alertDefinitions';
    }

    static getPathFromGroup(group: AlertCategoryGroup): string {
        switch (group) {
            case AlertCategoryGroup.ANOMALY:
                return 'anomalies';
            case AlertCategoryGroup.FAILURE:
                return 'failures';
            case AlertCategoryGroup.OPERATION:
                return 'operations';
            case AlertCategoryGroup.MAINTENANCE:
                return 'maintenance';
            default:
                return '';
        }
    }

    static getGroupFromPath(groupPath: string): AlertCategoryGroup {
        switch (groupPath) {
            case 'anomalies':
                return AlertCategoryGroup.ANOMALY;
            case 'failures':
                return AlertCategoryGroup.FAILURE;
            case 'operations':
                return AlertCategoryGroup.OPERATION;
            case 'maintenance':
                return AlertCategoryGroup.MAINTENANCE;
            default:
                return null;
        }
    }

    static getGroupFromSeverity(e: AlertDefinition | LocationAlertDefinition): AlertCategoryGroup {
        switch (e.eventSeverity) {
            case 1:
                return AlertCategoryGroup.OPERATION;
            case 2:
            case 3:
                return AlertCategoryGroup.ANOMALY;
            case 4:
                return AlertCategoryGroup.FAILURE
            case 5:
                return AlertCategoryGroup.MAINTENANCE;
            default:
                return null;
        }
    }

    import(file: File, delimiter: string, eventOnly: boolean, category?: AlertCategoryGroup): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        let params = new HttpParams().set('eventOnly', eventOnly);
        params = params.set('delimiter', delimiter);
        params = this.getContextParams(params);
        if (category) {
            params = this.getSeverityParams(params, category);
        }
        return firstValueFrom(this.httpClient.post<any>(AppConstants.API_BASE_URL + `${this.getEndpoint()}/import`, formData, { params: params }));
    }

    export(eventOnly: boolean, category?: AlertCategoryGroup): Promise<void> {
        let params = new HttpParams().set('eventOnly', eventOnly);
        params = this.getContextParams(params);
        let entityName = eventOnly ? 'event' : 'alert';
        if (category) {
            params = this.getSeverityParams(params, category);
            entityName = category.toLocaleLowerCase();
        }
        return firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + `${this.getEndpoint()}/export`, { params: params, observe: "response", responseType: "blob" }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, entityName + '_export.csv') });
    }

    private getSeverityParams(params: HttpParams, category: AlertCategoryGroup): HttpParams {
        switch (category) {
            case AlertCategoryGroup.ANOMALY:
                params = params.set('eventSeverity', 2)
                params = params.append('eventSeverity', 3)
                break;
            case AlertCategoryGroup.FAILURE:
                params = params.set('eventSeverity', 4)
                break;
            case AlertCategoryGroup.OPERATION:
                params = params.set('eventSeverity', 1)
                break;
            case AlertCategoryGroup.MAINTENANCE:
                params = params.set('eventSeverity', 5)
                break;
        }
        return params;
    }

    getContinuousCount(): Promise<{ count: number }> {
        return firstValueFrom(this.httpClient.get<{ count: number }>(AppConstants.API_BASE_URL + `${this.getEndpoint()}/continuousCount`, { params: this.getContextParams() }));
    }
}